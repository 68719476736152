import SEO from '@common/components/SEO';
// import SliderComponent from '@common/components/Slider';
// import { Banner, SectionEnum } from '@common/models/sections';
import {
  addProductToCart,
  CartSliceType,
  removeProductFromCart,
  subtractProductFromCart,
} from '@common/store/cart/slice';
import {
  CategoriesSliceType,
  fetchCategoriesRequest,
  getCategoryQueryParamsInitialState,
} from '@common/store/categories/slice';
import { CustomPagesSliceType } from '@common/store/customPages/slice';
import { FeaturesSliceType } from '@common/store/features/slice';
import { FilterBarSliceType } from '@common/store/filterBar/slice';
import { HomeSliceType } from '@common/store/home/slice';
import { PoliciesSliceTosType } from '@common/store/policiesTos/slice';
import {
  fetchProductsRequest,
  // getProductQueryParamsInitialState,
  ProductsSliceType,
  // resetProductsStore,
} from '@common/store/products/slice';
import { fetchSearchRequest, SearchSliceType } from '@common/store/search/slice';
import { SliderSliceType } from '@common/store/slider/slice';
import { StoreInfoSliceType } from '@common/store/storeInfo/slice';
import { addToast, removeToast, ToastWithKeyType } from '@common/store/toasts/slice';
import { UserProfileSliceType } from '@common/store/userProfile/slice';
import { UserSectionsSliceType } from '@common/store/userSections/slice';
// import { isBrowser } from '@common/utils';
// import { PRODUCTS_KEY } from '@common/utils/constants';
// import Branding from '@components/Branding';
// import FilterBar from '@components/FilterBar';
import Footer from '@components/Footer';
import Toasts from '@components/Toasts';
import Navbar from '@layouts/Navbar';
import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../../common/store';

export interface PageLayoutProps {
  children: React.ReactNode;
  topChildren?: React.ReactNode;
  cart: CartSliceType;
  products: Record<number, ProductsSliceType>;
  toasts: ToastWithKeyType[];
  storeInfo: StoreInfoSliceType;
  home: HomeSliceType;
  slider: SliderSliceType;
  search: Record<string, SearchSliceType>;
  addProductToCart: typeof addProductToCart;
  removeProductFromCart: typeof removeProductFromCart;
  subtractProductFromCart: typeof subtractProductFromCart;
  addToast: typeof addToast;
  fetchProductsRequest: typeof fetchProductsRequest;
  fetchCategoriesRequest: typeof fetchCategoriesRequest;
  categories: CategoriesSliceType;
  removeToast: typeof removeToast;
  showBanner?: boolean;
  fetchSearchRequest: typeof fetchSearchRequest;
  customPages: CustomPagesSliceType;
  userProfile: UserProfileSliceType;
  userSections: UserSectionsSliceType;
  features: FeaturesSliceType;
  hideToast?: boolean;
  filterBar: FilterBarSliceType;
  policiesTos: PoliciesSliceTosType;
}

const PageLayout: React.FunctionComponent<PageLayoutProps> = ({
  customPages,
  // cart,
  toasts,
  children,
  fetchCategoriesRequest,
  categories,
  // addProductToCart,
  // removeProductFromCart,
  // subtractProductFromCart,
  addToast,
  removeToast,
  storeInfo,
  // products,
  // slider,
  // home,
  // showBanner,
  // fetchSearchRequest,
  // search,
  // userProfile,
  userSections,
  features,
  hideToast = false,
  filterBar,
  policiesTos,
}) => {
  if (!categories.isFetched && !categories.isFetching) {
    fetchCategoriesRequest({
      queryParams: getCategoryQueryParamsInitialState(),
      ordering: 'order',
    });
  }
  const pageHeaderLayout = userSections.userSections?.find((section) => section.sectionType === 'Page Header');
  return (
    <div className="bg-page pageLayout">
      {pageHeaderLayout ? <Navbar id={pageHeaderLayout.id} /> : null}
      <SEO />
      {!hideToast && (
        <div className="w-auto max-w-410 mx-auto">
          <Toasts toasts={toasts} addToast={addToast} removeToast={removeToast} />
        </div>
      )}
      <div>{children}</div>
      <Footer
        storeInfo={storeInfo.storeInfo}
        customPages={customPages}
        features={features}
        filterBar={filterBar}
        policies={policiesTos}
        pageHeaderID={pageHeaderLayout?.id}
      />
      {/* {!features?.features?.whiteLabel ? <Branding storeInfo={storeInfo.storeInfo} className="mt-40" /> : null} */}
    </div>
  );
};

export default connect(
  ({
    cart,
    products,
    slider,
    toasts,
    categories,
    storeInfo,
    home,
    search,
    customPages,
    userProfile,
    userSections,
    features,
    filterBar,
    policiesTos,
  }: RootState) => ({
    search,
    home,
    cart,
    slider,
    products,
    categories,
    toasts,
    storeInfo,
    customPages,
    userProfile,
    userSections,
    features,
    filterBar,
    policiesTos,
  }),
  {
    addProductToCart,
    removeProductFromCart,
    fetchCategoriesRequest,
    fetchProductsRequest,
    subtractProductFromCart,
    addToast,
    removeToast,
    fetchSearchRequest,
  },
)(PageLayout);
